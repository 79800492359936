
























































































import {Component, Prop} from 'vue-property-decorator'
import TokensPriceTable from '@/components/tokensprice/TokensPriceTable.vue'
import MyWalletTokensTable from '@/components/myWallet/MyWalletTokensTable.vue'
import {MyWalletTokensTableItem} from '@/model/resource/MyWalletTokensTableItem'
import Tooltip from '@/components/tooltip/Tooltip.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {FlamingoSwapHelper} from '@/libs/blockchain-services/helpers/FlamingoSwapHelper'
import {DEFAULT_NETWORK} from '@/libs/blockchain-services/constants/BSNeo3Constants'
import MyWalletSwapModal from './MyWalletSwap/MyWalletSwapModal.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import MyWalletMyNfts from '@/components/myWallet/MyWalletMyNfts.vue'
import {GMNftAssetCollection} from '@/model/collection/GhostMarket/GMNftAssetCollection'
import {GMNftCollectionCollection} from '@/model/collection/GhostMarket/GMNftCollectionCollection'
import {SortOption} from '@/types/SortOption'

@Component({
  components: {
    MyWalletMyNfts,
    Tooltip,
    MyWalletTokensTable,
    MyWalletSwapModal,
    TokensPriceTable,
  },
})
export default class MyWalletTokensNftsSection extends MixinScreenSize {
  @Prop({type: Array, required: true})
  myWalletTokensTableItems!: MyWalletTokensTableItem[]
  @Prop({type: String, required: true})
  lastPriceUpdated!: string
  @Prop({type: TokensTableItemsCollection, required: true})
  tokensTableItemsCollection!: TokensTableItemsCollection
  @Prop({type: GMNftAssetCollection, required: true})
  nftAssetCollection!: GMNftAssetCollection
  @Prop({type: GMNftCollectionCollection, required: true})
  nftCollectionCollection!: GMNftCollectionCollection

  swappableTokensSymbol = FlamingoSwapHelper.listSwappableTokensSymbol(
    DEFAULT_NETWORK
  )

  isTokensTableVisible = true

  onFilterNftAssetCollection(option: SortOption) {
    this.$emit('filterNftAssetCollection', option)
  }

  onFilterNftAsset(option: SortOption) {
    this.$emit('filterNftAsset', option)
  }

  goToFirstPageNftAssets() {
    this.$emit('goToFirstPageNftAssets')
  }

  goToNextPageNftAssets() {
    this.$emit('goToNextPageNftAssets')
  }

  goToPreviousPageNftAssets() {
    this.$emit('goToPreviousPageNftAssets')
  }
}
