
























import {Component, Prop, Vue} from 'vue-property-decorator'
import {TokenPricedCollection} from '@/model/collection/TokenPricedCollection'

@Component({})
export default class TokenPricedToggleButton extends Vue {
  @Prop({type: Object, required: true}) collection!: TokenPricedCollection

  value = false

  mounted() {
    this.value = !this.collection.isDeprecated
  }

  changeValue() {
    this.value = !this.value

    this.collection.isDeprecated = this.value

    this.$emit('filter', this.value)
  }
}
