import {IResource} from '@simpli/resource-collection'

export class GMNftAsset implements IResource {
  id: number | null = null
  name: string | null = null
  imageUrl: string | null = null
  collectionName: string | null = null
  collectionImageUrl: string | null = null
  contractChain: string | null = null
  contractHash: string | null = null

  get $id() {
    return 0
  }

  set $id(value: number) {
    // do nothing
  }

  get $tag() {
    return `${this.name}`
  }

  get collectionUrl() {
    return `https://ghostmarket.io/pt/collection/${this.collectionName}`
  }

  get assetUrl() {
    return `https://ghostmarket.io/pt/asset/${this.contractChain}/${this.contractHash}/${this.id}`
  }
}
