





































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {GMNftAsset} from '@/model/resource/GhostMarket/GMNftAsset'

@Component
export default class NftAssetCard extends Vue {
  @Prop({type: GMNftAsset, required: true}) nftAsset!: GMNftAsset
}
