

























































































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import ExchangeCard from '@/components/cards/ExchangeCard.vue'
import {AdapSearchfield} from '@simpli/vue-adap-table'
import FilterNftAssetCollection from '@/components/filters/FilterNftAssetCollection.vue'
import FilterNftAsset from '@/components/filters/FilterNftAsset.vue'
import NftAssetCard from '@/components/cards/NftAssetCard.vue'
import {GMNftAssetCollection} from '@/model/collection/GhostMarket/GMNftAssetCollection'
import {GMNftCollectionCollection} from '@/model/collection/GhostMarket/GMNftCollectionCollection'
import {$} from '@/facade'
import {SortOption} from '@/types/SortOption'
import TopNftsCard from '@/components/cards/TopNftsCard.vue'
import Swiper from 'swiper'

@Component({
  components: {
    TopNftsCard,
    AdapSearchfield,
    FilterNftAssetCollection,
    FilterNftAsset,
    NftAssetCard,
  },
})
export default class MyWalletMyNfts extends Vue {
  @Prop({type: GMNftAssetCollection, required: true})
  nftAssetCollection!: GMNftAssetCollection
  @Prop({type: GMNftCollectionCollection, required: true})
  nftCollectionCollection!: GMNftCollectionCollection

  swiper!: Swiper

  get swiperOption() {
    return {
      pagination: {
        el: '.stats-swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1.2, // Mostra um pedaço do próximo card
          slidesPerGroup: 1,
          spaceBetween: 12,
        },
        768: {
          slidesPerView: 2.5, // No tablet, mostra parcialmente o próximo card
          slidesPerGroup: 2,
          spaceBetween: 24,
        },
      },
    }
  }

  onFilterNftAssetCollection(option: SortOption) {
    this.$emit('filterNftAssetCollection', option)
  }

  onFilterNftAsset(option: SortOption) {
    this.$emit('filterNftAsset', option)
  }

  goToFirstPageNftAssets() {
    this.$emit('goToFirstPageNftAssets')
  }

  goToNextPageNftAssets() {
    this.$emit('goToNextPageNftAssets')
  }

  goToPreviousPageNftAssets() {
    this.$emit('goToPreviousPageNftAssets')
  }

  async initSwiper(swiper: Swiper) {
    this.swiper = swiper
  }
}
