import {IResource} from '@simpli/resource-collection'

export class GMNftCollection implements IResource {
  id: string | null = null
  name: string | null = null
  slug: string | null = null

  get $id() {
    return 0
  }

  set $id(val) {
    // do nothing
  }

  get $tag() {
    return `${this.slug}`
  }
}
