/**
 * Environment variables defined in .env[.*]
 */

export abstract class EnvHelper {
  static readonly NODE_ENV = process.env.NODE_ENV!
  static readonly BASE_URL = process.env.BASE_URL!

  /** Application */
  static readonly VUE_APP_ENV = process.env.VUE_APP_ENV!

  /** Colors */
  static readonly REGENT_GRAY_COLOR = process.env.VUE_APP_REGENT_GRAY_COLOR!
  static readonly OCEAN_GREEN_COLOR = process.env.VUE_APP_OCEAN_GREEN_COLOR!
  static readonly AQUAMARINE_COLOR = process.env.VUE_APP_AQUAMARINE_COLOR!
  static readonly ILLUSION_COLOR = process.env.VUE_APP_ILLUSION_COLOR!

  /** Screen sizes */
  static readonly SMALL_SCREEN = Number(process.env.VUE_APP_SMALL_SCREEN!)
  static readonly MEDIUM_SCREEN = Number(process.env.VUE_APP_MEDIUM_SCREEN!)
  static readonly LARGE_SCREEN = Number(process.env.VUE_APP_LARGE_SCREEN!)

  /** Swiper */
  static readonly SWIPER_PER_PAGE_MOBILE = Number(
    process.env.VUE_APP_SWIPER_PER_PAGE_MOBILE
  )
  static readonly SWIPER_PER_PAGE_TABLET = Number(
    process.env.VUE_APP_SWIPER_PER_PAGE_TABLET
  )
  static readonly SWIPER_PER_PAGE_DESKTOP = Number(
    process.env.VUE_APP_SWIPER_PER_PAGE_DESKTOP
  )

  /** Urls */
  static readonly GASBOT_DISCORD_URL = process.env.VUE_APP_GASBOT_DISCORD_URL!
  static readonly NEO_FEED_URL = process.env.VUE_APP_NEO_FEED_URL!
  static readonly DORA_URL = process.env.VUE_APP_DORA_URL!
  static readonly SHRIKE_URL = process.env.VUE_APP_SHRIKE_URL!

  /** N3 */
  static readonly VUE_APP_N3_HIDE = process.env.VUE_APP_N3_SHOW! === 'HIDE'

  /** Rollbar */
  static readonly VUE_APP_ROLLBAR_ACCESS_TOKEN = process.env
    .VUE_APP_ROLLBAR_ACCESS_TOKEN!

  /** GhostMarket */
  static readonly VUE_APP_BASE_GHOSTMARKET_COLLECTION_URL =
    process.env.VUE_APP_BASE_GHOSTMARKET_COLLECTION_URL

  /** Wallet Connect */
  static readonly VUE_APP_WALLET_CONNECT_NETWORK = process.env
    .VUE_APP_WALLET_CONNECT_NETWORK!

  /** RPC URL */
  static readonly VUE_APP_RPC_URL = String(process.env.VUE_APP_RPC_URL)

  /** Token Decimals */
  static readonly VUE_APP_BNEO_DECIMALS = Number(
    process.env.VUE_APP_BNEO_DECIMALS
  )

  /** Token Total Supply */
  static readonly VUE_APP_NEO_TOTAL_SUPPLY = String(
    process.env.VUE_APP_NEO_TOTAL_SUPPLY
  )

  /** Common Contract Script Hashes */
  static readonly VUE_APP_GAS_SCRIPT_HASH = String(
    process.env.VUE_APP_GAS_SCRIPT_HASH
  )
  static readonly VUE_APP_NEO_SCRIPT_HASH = String(
    process.env.VUE_APP_NEO_SCRIPT_HASH
  )
  static readonly VUE_APP_BNEO_SCRIPT_HASH = String(
    process.env.VUE_APP_BNEO_SCRIPT_HASH
  )
  static readonly VUE_APP_CNEO_SCRIPT_HASH = String(
    process.env.VUE_APP_CNEO_SCRIPT_HASH
  )

  /** Swap Multi Invoke */
  static readonly VUE_APP_FLAMINGO_ROUTER_SCRIPT_HASH = String(
    process.env.VUE_APP_FLAMINGO_ROUTER_SCRIPT_HASH
  )
  static readonly VUE_APP_FLAMINGO_PAIR_WHITELIST_SCRIPT_HASH = String(
    process.env.VUE_APP_FLAMINGO_PAIR_WHITELIST_SCRIPT_HASH
  )
  static readonly VUE_APP_FLAMINGO_FACTORY_SCRIPT_HASH = String(
    process.env.VUE_APP_FLAMINGO_FACTORY_SCRIPT_HASH
  )
  static readonly VUE_APP_FLP_BNEO_GAS_PAIR_SCRIPT_HASH = String(
    process.env.VUE_APP_FLP_BNEO_GAS_PAIR_SCRIPT_HASH
  )
  static readonly VUE_APP_NEO_PROXY_SCRIPT_HASH = String(
    process.env.VUE_APP_NEO_PROXY_SCRIPT_HASH
  )
  static readonly VUE_APP_MAX_ATTEMPTS_TO_VALIDATE_TRANSACTION = 25

  /** GAS Calculator */
  static readonly VUE_APP_NO_CANDIDATE_SCRIPT_HASH = String(
    process.env.VUE_APP_NO_CANDIDATE_SCRIPT_HASH
  )
  static readonly VUE_APP_NNT_SCRIPT_HASH = String(
    process.env.VUE_APP_NNT_SCRIPT_HASH
  )
}
