import {
  HttpExclude,
  RequestExpose,
  ResponseExpose,
} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {GMNftAsset} from '@/model/resource/GhostMarket/GMNftAsset'
import axios from 'axios'
import {$} from '@/facade'

@HttpExclude()
export class GMNftAssetCollection extends ExpansibleCollection<GMNftAsset> {
  resource?: IGMNftAssetCollectionResourceHolder

  @RequestExpose() ownerAddress: string | null = null
  @RequestExpose() orderBy: string | null = null
  @RequestExpose() orderDirection: string | null = null
  @RequestExpose() collectionSlug: string | null = null
  @RequestExpose() cursor: string | null = null
  @RequestExpose() limit: number | null = null

  cursors: string[] = []
  nextCursor: string | null = null
  currentCursorIndex: number = -1

  constructor() {
    super(GMNftAsset)
  }

  isOnFirstPage(): boolean {
    return this.currentCursorIndex <= -1
  }

  hasPreviousPage(): boolean {
    return this.currentCursorIndex > -1
  }

  hasNextPage(): boolean {
    return !!this.nextCursor
  }

  async goToPreviousPage(): Promise<void> {
    if (this.hasPreviousPage()) {
      this.currentCursorIndex--
      this.cursor = this.cursors[this.currentCursorIndex]
      await this.queryAsPage()
    }
  }

  async goToNextPage(): Promise<void> {
    if (this.hasNextPage()) {
      this.cursor = this.nextCursor
      await this.queryAsPage()
    }
  }

  async queryAsPage(): Promise<void> {
    return this.listNftAssetCollection()
  }

  async listNftAssetCollection(): Promise<void> {
    const baseUrl = `https://api.ghostmarket.io/api/v2/assets`
    const params = new URLSearchParams()

    if (this.cursor) {
      params.append('cursor', this.cursor)
    }

    if (this.ownerAddress) {
      params.append('owners[]', this.ownerAddress)
    }

    if (this.orderBy) {
      params.append('orderBy', this.orderBy)
    }

    if (this.limit) {
      params.append('size', String(this.limit))
    }

    if (this.orderDirection) {
      params.append('orderDirection', this.orderDirection)
    }

    if (this.collectionSlug && this.collectionSlug !== 'All') {
      params.append('collection', this.collectionSlug)
    }

    if (this.search && this.search !== '') {
      $.await.init('filterNfts')
      params.append('name', this.search)
    }

    const url = `${baseUrl}?${params.toString()}`
    const {data} = await axios.get(url)

    this.items = data.assets.map((asset: any) => {
      const nftAsset = new GMNftAsset()
      nftAsset.id = asset.tokenId
      nftAsset.name = asset.metadata?.name
      nftAsset.imageUrl = asset.metadata?.mediaUri
      nftAsset.contractChain = asset.contract?.chain
      nftAsset.contractHash = asset.contract?.hash
      nftAsset.collectionName = asset.collection?.name
      nftAsset.collectionImageUrl = asset.collection?.logoUrl
      return nftAsset
    })

    this.nextCursor = data.next

    if (this.cursor && !this.cursors.includes(this.cursor)) {
      this.cursors.push(this.cursor)
    }

    this.currentCursorIndex = this.cursors.indexOf(this.cursor!)

    if (this.search && this.search !== '') {
      $.await.done('filterNfts')
    }
  }
}

export interface IGMNftAssetCollectionResourceHolder {}
