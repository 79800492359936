


















































import {MyWalletTokensTableItem} from '@/model/resource/MyWalletTokensTableItem'
import {bsNeo3} from '@/libs/bsNeo3'
import {Mixins, Component, Watch} from 'vue-property-decorator'
import NavbarMenu from '@/components/NavbarMenu.vue'
import AppFooter from '@/components/AppFooter.vue'
import MyWalletInfo from '@/components/myWallet/MyWalletSection.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import MyTokensNftsSection from '@/components/myWallet/MyWalletTokensNftsSection.vue'
import MyTokensGasCalculatorSection from '@/components/myWallet/MyWalletGasCalculatorSection.vue'
import MyTokensGetGasSection from '@/components/myWallet/MyWalletGetGasSection.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import {$} from '@/facade'
import {WalletsStatus} from '@/model/wallets/types/WalletTypes'
import {NeoHelper} from '@/helpers/NeoHelper'
import {BalanceResponse} from '@/libs/blockchain-services/types'
import {GMNftAssetCollection} from '@/model/collection/GhostMarket/GMNftAssetCollection'
import {GMNftCollectionCollection} from '@/model/collection/GhostMarket/GMNftCollectionCollection'
import {SortOption} from '@/types/SortOption'
import MyWalletMyNfts from '@/components/myWallet/MyWalletMyNfts.vue'

@Component({
  components: {
    MyWalletMyNfts,
    MyTokensGetGasSection,
    MyTokensGasCalculatorSection,
    MyTokensNftsSection,
    MyWalletInfo,
    AppFooter,
    NavbarMenu,
  },
})
export default class MyWalletView extends Mixins(MixinScreenSize) {
  tokensTableItemsCollection = new TokensTableItemsCollection().noPagination()
  nftAssetCollection = new GMNftAssetCollection()
  nftCollectionCollection = new GMNftCollectionCollection()

  balanceResponse: BalanceResponse[] = []

  unclaimedGas = '0'
  fee = '0'

  myWalletTokensTableItems: MyWalletTokensTableItem[] = []
  myWalletTokensLastPriceUpdated = ''

  walletIsStarted = false
  isRefreshing = false

  get neoBalance() {
    return Number(
      this.balanceResponse.find(item => item.token.symbol === 'NEO')?.amount ??
        0
    )
  }

  get totalBalanceAmountInDollar() {
    return this.myWalletTokensTableItems.reduce(
      (acc, item) => acc + item.amountInDollar,
      0
    )
  }

  get totalBalancePercentageVariation() {
    return 0 // TODO: Implement this logic
  }

  async goToNextPageNftAssets() {
    await $.await.run('filterNfts', async () => {
      await this.nftAssetCollection.goToNextPage()
    })
  }

  async goToPreviousPageNftAssets() {
    await $.await.run('filterNfts', async () => {
      await this.nftAssetCollection.goToPreviousPage()
    })
  }

  async goToFirstPageNftAssets() {
    this.nftAssetCollection.cursor = null

    await $.await.run('filterNfts', async () => {
      await this.populateNftAssets()
    })
  }

  mounted() {
    this.onWalletStatusChange(this.$store.state.walletAdapter.walletsStatus)
  }

  async populateUnclaimedGas() {
    const {unclaimed} = await NeoHelper.getUnclaimedGasData()
    const {total} = await NeoHelper.calculateUnclaimedGasFee()

    this.unclaimedGas = (unclaimed * 10 ** -8).toFixed(8)
    this.fee = total.toString()
  }

  async populateAccountBalance(address: string) {
    try {
      this.balanceResponse = await bsNeo3.blockchainDataService.getBalance(
        address
      )
    } catch (error) {
      this.$toast.abort('Error fetching account balance. Please try again.')
    }
  }

  // @Watch('$store.state.walletAdapter.address')
  // onAddressChange(address: string | null) {
  //   if (!address && this.walletIsStarted) {
  //     this.$router.push('/')
  //   }
  // }

  @Watch('$store.state.walletAdapter.walletsStatus')
  async onWalletStatusChange(status: WalletsStatus) {
    const walletIsConnectedWithError = Object.values(status).some(
      statusName => statusName === 'error'
    )

    // if (walletIsConnectedWithError) {
    //   this.$toast.abort('Error connecting to wallet. Please try again.')
    //   await this.$router.push('/')
    //   return
    // }

    const walletIsStarted = Object.values(status).some(
      statusName => statusName === 'started'
    )
    if (!walletIsStarted) return

    await this.populateResources()

    this.walletIsStarted = true
  }

  async populateResources() {
    $.await.init('populateMyWalletAssets')

    if (!this.$walletAdapter.address) {
      await this.$utils.sleep(1000)

      if (!this.$walletAdapter.address) {
        await this.$router.push('/')
        return
      }
    }

    this.nftAssetCollection.limit = 15
    this.nftAssetCollection.orderBy = 'mintDate'
    this.nftAssetCollection.orderDirection = 'Desc'

    const promises: Promise<unknown>[] = [
      this.populateAccountBalance(this.$walletAdapter.address),
      this.populateTokens(),
      this.populateUnclaimedGas(),
      this.populateNftAssets(),
      this.populateNftCollections(),
    ]

    await Promise.allSettled(promises)

    this.convertToMyWalletTokensTableItems()

    $.await.done('populateMyWalletAssets')
  }

  convertToMyWalletTokensTableItems() {
    const myWalletTokens: MyWalletTokensTableItem[] = []

    this.balanceResponse.forEach(item => {
      if (item.amount === '0') return

      const token = this.tokensTableItemsCollection.items.find(
        token => token.marketInformation?.hash === item.token.hash
      )

      if (!token) return

      const myWalletTokensTableItem = new MyWalletTokensTableItem()

      myWalletTokensTableItem.imageUrl = token.imageUrl
      myWalletTokensTableItem.symbol = token.symbol
      myWalletTokensTableItem.marketInformation = token.marketInformation
      myWalletTokensTableItem.blockchainVersion = token.blockchainVersion
      myWalletTokensTableItem.amount = Number(item.amount)

      myWalletTokens.push(myWalletTokensTableItem)
    })

    this.myWalletTokensTableItems = myWalletTokens
    this.myWalletTokensLastPriceUpdated = this.tokensTableItemsCollection.lastTimeUpdatedFormatted
  }

  async populateTokens() {
    await this.tokensTableItemsCollection.queryAsPage()
  }

  async populateNftAssets() {
    this.nftAssetCollection.ownerAddress = this.$walletAdapter.address

    await this.nftAssetCollection.queryAsPage()
  }

  async populateNftCollections() {
    this.nftCollectionCollection.ownerAddress = this.$walletAdapter.address

    await this.nftCollectionCollection.queryAsPage()
  }

  async handleRefreshData() {
    this.isRefreshing = true

    await this.populateResources()

    this.isRefreshing = false
  }

  async onFilterNftAsset(option: SortOption) {
    if (!option.field) return

    this.nftAssetCollection.orderBy = option.field
    this.nftAssetCollection.orderDirection = option.asc ? 'Asc' : 'Desc'

    await $.await.run('filterNfts', async () => {
      await this.populateNftAssets()
    })
  }

  async onFilterNftAssetCollection(option: SortOption) {
    if (!option.field) return

    this.nftAssetCollection.collectionSlug = option.field

    await $.await.run('filterNfts', async () => {
      await this.populateNftAssets()
    })
  }
}
