







































































































































import {Vue, Component} from 'vue-property-decorator'
import {NeoHelper} from '@/helpers/NeoHelper'
import MyWalletClaimGasModal from '@/components/myWallet/MyWalletClaimGasModal.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {MyWalletClaimGasModal},
})
export default class GasCalculatorUnclaimedGas extends MixinScreenSize {
  unclaimedGas = '0'
  fee = '0'

  get isProceedDisabled() {
    return Number(this.fee) >= Number(this.unclaimedGas)
  }

  openClaimGasModal() {
    this.$modal.open('myWalletClaimGasModal')
  }

  mounted() {
    this.populateUnclaimedGas()
  }

  async populateUnclaimedGas() {
    this.$await.run('populateUnclaimedGas', async () => {
      const {unclaimed} = await NeoHelper.getUnclaimedGasData()
      const {total} = await NeoHelper.calculateUnclaimedGasFee()

      this.unclaimedGas = (unclaimed * 10 ** -8).toFixed(8)
      this.fee = total.toString()
    })
  }
}
