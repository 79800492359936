












































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import _ from 'lodash'
import {TranslateResult} from 'vue-i18n'
import {InputSelect} from '@simpli/vue-input'
import {ContractCollection} from '@/model/collection/ContractCollection'
import {FilterContractClientSchema} from '@/schema/resource/Contract/FilterContractClientSchema'
import {$} from '@/facade'
import FilterContractClientFiltered from '@/components/filters/FilterContractClientFiltered.vue'
import FilterDappMobile from '@/components/filters/FilterDappMobile.vue'
import {SortOption} from '@/types/SortOption'

export const getSortOptions = (): SortOption[] => [
  {
    $id: 1,
    field: 'title',
    asc: true,
    $tag: $.translate('schema.FilterContract.nameLow'),
  },
  {
    $id: 2,
    field: 'title',
    asc: false,
    $tag: $.translate('schema.FilterContract.nameHigh'),
  },
  {
    $id: 3,
    field: 'deploymentDate',
    asc: false,
    $tag: $.translate('schema.FilterContract.dateDeployedRecentFirst'),
  },
  {
    $id: 4,
    field: 'deploymentDate',
    asc: true,
    $tag: $.translate('schema.FilterContract.dateDeployedRecentLast'),
  },
  {
    $id: 5,
    field: 'recentTransactionCount',
    asc: true,
    $tag: $.translate('schema.FilterContract.transactionLow'),
  },
  {
    $id: 6,
    field: 'recentTransactionCount',
    asc: false,
    $tag: $.translate('schema.FilterContract.transactionHigh'),
  },
  {
    $id: 7,
    field: 'percentageGrowth',
    asc: true,
    $tag: $.translate('schema.FilterContract.daysLow'),
  },
  {
    $id: 8,
    field: 'percentageGrowth',
    asc: false,
    $tag: $.translate('schema.FilterContract.daysHigh'),
  },
]

@Component({
  components: {
    FilterDappMobile,
    FilterContractClientFiltered,
    InputSelect,
  },
})
export default class FilterContractClient extends Vue {
  @Prop({type: Object, required: true}) collection!: ContractCollection
  @Prop({type: Object, required: true}) sorterField!: SortOption

  sorterFieldMutation = this.sorterField
  showModal: boolean = false
  schema = new FilterContractClientSchema()
  sortOptions: SortOption[] = getSortOptions()

  async mounted() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    const [, selectedOption] = this.sortOptions.slice()
    this.sorterFieldMutation = _.cloneDeep(selectedOption)
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionBlockchainVersion.queryAsPage(),
    ]

    await this.$await.run('softQuery', async () => await Promise.all(promises))
  }

  async doFilter() {
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.queryToFirstPage())
  }

  async doClear() {
    this.collection.dapp = []
    this.collection.blockchainVersion = null
    await this.doFilter()
  }

  async onSortChange() {
    if (!this.sorterFieldMutation) return

    this.collection.setOrderBy(this.sorterFieldMutation.field)
    this.collection.setAsc(this.sorterFieldMutation?.asc || false)
    this.collection.setCurrentPage(0)
    await this.doFilter()
  }

  @Watch('collection', {deep: true})
  onCollectionChange() {
    const orderByFoundInSortOptions = this.sortOptions.find(
      sortOption =>
        sortOption.field === this.collection.orderBy &&
        sortOption.asc === this.collection.asc
    )

    if (!orderByFoundInSortOptions) {
      this.sorterFieldMutation = {
        $id: 0,
        field: $.translate('schema.FilterDapp.sortBy'),
        asc: true,
        $tag: $.translate('schema.FilterDapp.sortBy'),
      }
      return
    }
  }

  private toggleModal(): void {
    this.showModal = !this.showModal
  }
}
