












































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator'
import TokenCellRender from '@/components/render/TokenCellRender.vue'
import TokensSwapButton from '@/components/tokensprice/TokensSwapButton.vue'
import PercentageProgressRender from '@/components/render/PercentageProgressRender.vue'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import PoweredBy, {PoweredByItem} from '@/components/PoweredBy.vue'
import {MyWalletTokensTableItem} from '@/model/resource/MyWalletTokensTableItem'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {
    PoweredBy,
    LastPriceUpdated,
    PercentageProgressRender,
    TokensSwapButton,
    TokenCellRender,
    BounceLoader,
  },
})
export default class MyWalletTokensTable extends MixinScreenSize {
  @Prop({type: Array, required: true})
  myWalletTokensTableItems!: MyWalletTokensTableItem[]
  @Prop({type: String, required: true}) lastPriceUpdated!: string

  headers = ['TOKEN', 'BALANCE', 'VALUE', 'PRICE', '24H %', '7D %', '']

  displayedTokens: MyWalletTokensTableItem[] = []
  moreItemsAvailableToDisplay: MyWalletTokensTableItem[] = []

  poweredByList = [
    {
      url: 'https://flamingo.finance/',
      alt: 'flamingo icon',
      filename: 'flamingo-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://www.forthewin.network/#/',
      alt: 'ftwicon',
      filename: 'for-the-win-logo.svg',
    } as PoweredByItem,
    {
      url: 'https://neofura-1.gitbook.io/api-docs/',
      alt: 'magnet icon',
      filename: 'magnet-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://carrot-fi.xyz/',
      alt: 'carrot swap icon',
      filename: 'carrot-swap.svg',
    } as PoweredByItem,
  ]

  mounted() {
    this.populateResources()
  }

  populateResources() {
    this.displayedTokens = this.myWalletTokensTableItems.slice(0, 5)

    this.moreItemsAvailableToDisplay = this.myWalletTokensTableItems.slice(5)
  }

  @Watch('myWalletTokensTableItems')
  onMyWalletTokensTableItemsChange() {
    this.populateResources()
  }

  loadMore() {
    const moreItems = this.moreItemsAvailableToDisplay.splice(0, 5)

    this.displayedTokens.push(...moreItems)
  }
}
