import BigNumber from 'bignumber.js'
import {
  FlamingoSwapTokens,
  FlamingoSwapScriptHashes,
  FlamingoSwapPools,
  BSNeo3NetworkId,
} from '@/libs/blockchain-services/types'

export const BLOCKCHAIN_WSS_URL = 'wss://rpc10.n3.nspcc.ru:10331/ws'

export const BN_0 = new BigNumber(0)
export const BN_1 = new BigNumber(1)
export const BN_997 = new BigNumber(997)
export const BN_1000 = new BigNumber(1000)
export const FEE_RATE = new BigNumber(0.003)
export const UNWRAPPING_FEE = 0.001

export const TESTNET_FLAMINGO_SWAP_TOKENS: FlamingoSwapTokens = {
  // ============ Neo Assets ============ //
  FLM: {
    symbol: 'FLM',
    decimals: 8,
    hash: '0x5b53998b399d10cd25727269e865acc785ef5c1a',
    name: 'FLM',
  },
  NUDES: {
    symbol: 'NUDES',
    decimals: 8,
    hash: '0xe2cd0c441f37f0daeeee196b5ce23bef4182c43a',
    name: 'NUDES',
  },
  CANDY: {
    symbol: 'CANDY',
    decimals: 9,
    hash: '0x7d4515866a633857c0ca5798aa66856768ae06fe',
    name: 'CANDY',
  },
  DOGER: {
    symbol: 'DOGER',
    decimals: 8,
    hash: '0x7761e3b1939f691feb01d791c4c2307bd195e9e5',
    name: 'DOGER',
  },
  NEO: {
    symbol: 'NEO',
    decimals: 0,
    hash: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
    name: 'NEO',
  },
  GAS: {
    symbol: 'GAS',
    decimals: 8,
    hash: '0xd2a4cff31913016155e38e474a2c06d08be276cf',
    name: 'GAS',
  },
  bNEO: {
    symbol: 'bNEO',
    decimals: 8,
    hash: '0x85deac50febfd93988d3f391dea54e8289e43e9e',
    name: 'bNEO',
  },
  SWTH: {
    symbol: 'SWTH',
    decimals: 8,
    hash: '0x70aba30ba83c344fa8c8d65939c93ca12e8c7409',
    name: 'SWTH',
  },
  GM: {
    symbol: 'GM',
    decimals: 8,
    hash: '0xc13b05fc0e6fe3cc681e29a574557784b4f79aff',
    name: 'GM',
  },
  SOM: {
    symbol: 'SOM',
    decimals: 8,
    hash: '0xfc7b372524289bc83f97647011698cb3325e8d9d',
    name: 'SOM',
  },
  FDE: {
    symbol: 'FDE',
    decimals: 8,
    hash: '0x5b769ec16f521711d7246c17ac107f7269bf56da',
    name: 'FDE',
  },
}

export const MAINNET_FLAMINGO_SWAP_TOKENS: FlamingoSwapTokens = {
  // ============ Neo Assets ============ //
  FLM: {
    symbol: 'FLM',
    decimals: 8,
    hash: '0xf0151f528127558851b39c2cd8aa47da7418ab28',
    name: 'FLM',
  },
  NEO: {
    symbol: 'NEO',
    decimals: 0,
    hash: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
    name: 'NEO',
  },
  GAS: {
    symbol: 'GAS',
    decimals: 8,
    hash: '0xd2a4cff31913016155e38e474a2c06d08be276cf',
    name: 'GAS',
  },
  bNEO: {
    symbol: 'bNEO',
    decimals: 8,
    hash: '0x48c40d4666f93408be1bef038b6722404d9a4c2a',
    name: 'bNEO',
  },
  FUSD: {
    symbol: 'FUSD',
    decimals: 8,
    hash: '0x1005d400bcc2a56b7352f09e273be3f9933a5fb1',
    name: 'FUSD',
  },
  LRB: {
    symbol: 'LRB',
    decimals: 8,
    hash: '0x8c07b4c9f5bc170a3922eac4f5bb7ef17b0acc8b',
    name: 'LRB',
  },
  SOM: {
    symbol: 'SOM',
    decimals: 8,
    hash: '0x2d4c6cf0417209a7eb410160344e224e74f87195',
    name: 'SOM',
  },
  CANDY: {
    symbol: 'CANDY',
    decimals: 9,
    hash: '0x88da18a5bca86ec8206d9b4960a7d0c4355a432f',
    name: 'CANDY',
  },
  DOGER: {
    symbol: 'DOGER',
    decimals: 8,
    hash: '0x322b5a366ca724801a1aa01e669b5f3d7f8c7f6f',
    name: 'DOGER',
  },
  FDE: {
    symbol: 'FDE',
    decimals: 8,
    hash: '0x9770f4d78a19d1a6fa94b472bcedffcc06b56c49',
    name: 'FDE',
  },
  GM: {
    symbol: 'GM',
    decimals: 8,
    hash: '0x9b049f1283515eef1d3f6ac610e1595ed25ca3e9',
    name: 'GM',
  },
  fCAKE: {
    symbol: 'fCAKE',
    decimals: 18,
    hash: '0xe65b462b90516012826f8a9c4c285d8c750e3a77',
    name: 'fCAKE',
  },
  SWTH: {
    symbol: 'SWTH',
    decimals: 8,
    hash: '0x78e1330db47634afdb5ea455302ba2d12b8d549f',
    name: 'SWTH',
  },
  NUDES: {
    symbol: 'NUDES',
    decimals: 8,
    hash: '0x340720c7107ef5721e44ed2ea8e314cce5c130fa',
    name: 'NUDES',
  },
  WBTC: {
    symbol: 'WBTC',
    decimals: 8,
    hash: '0x4548a3bcb3c2b5ce42bf0559b1cf2f1ec97a51d0',
    name: 'WBTC',
  },
  WETH: {
    symbol: 'WETH',
    decimals: 18,
    hash: '0xd3a41b53888a733b549f5d4146e7a98d3285fa21',
    name: 'WETH',
  },
  BNB: {
    symbol: 'BNB',
    decimals: 18,
    hash: '0x00fb9575f220727f71a1537f75e83af9387628ff',
    name: 'BNB',
  },
  USDT: {
    symbol: 'USDT',
    decimals: 6,
    hash: '0x68b938cc42b6a2d54fb9040f5facf4290ebb8c5f',
    name: 'USDT',
  },
  WINGv2: {
    symbol: 'WINGv2',
    decimals: 9,
    hash: '0x948a60635d1f7921063d04be8f6cb35c741df566',
    name: 'WING',
  },
  CAKE: {
    symbol: 'CAKE',
    decimals: 18,
    hash: '0x570c27653683788177f05740257d88fed76bf74b',
    name: 'CAKE',
  },
  ONT: {
    symbol: 'ONT',
    decimals: 9,
    hash: '0x0a1328bffb804ad7bb342673da82a972cc7af86c',
    name: 'ONT',
  },
}

export const FLAMINGO_SWAP_TOKENS: Record<
  BSNeo3NetworkId,
  FlamingoSwapTokens
> = {
  testnet: TESTNET_FLAMINGO_SWAP_TOKENS,
  mainnet: MAINNET_FLAMINGO_SWAP_TOKENS,
}

export const FLAMINGO_SWAP_SCRIPT_HASHES: Partial<Record<
  BSNeo3NetworkId,
  FlamingoSwapScriptHashes
>> = {
  mainnet: {
    flamingoSwapRouter: '0xf970f4ccecd765b63732b821775dc38c25d74f23',
    flamingoPairWhiteList: '0xfb75a5314069b56e136713d38477f647a13991b4',
    flamingoFactory: '0xca2d20610d7982ebe0bed124ee7e9b2d580a6efc',
  },
  testnet: {
    flamingoSwapRouter: '0x6f0910fa26290f4a423930c8f833395790c71705',
    flamingoPairWhiteList: '0xfb75a5314069b56e136713d38477f647a13991b4',
    flamingoFactory: '0xca2d20610d7982ebe0bed124ee7e9b2d580a6efc',
  },
}

export const TESTNET_FLAMINGO_SWAP_POOLS: FlamingoSwapPools = {
  'FLP-FLM-bNEO': {
    name: 'Flamingo Swap Pair: FLM, bNEO',
    hash: '0x35f47f3f697aaadfec954d5936d67f172413de7e',
    tokens: [
      FLAMINGO_SWAP_TOKENS.testnet.FLM,
      FLAMINGO_SWAP_TOKENS.testnet.bNEO,
    ],
  },
  'FLP-FLM-GAS': {
    name: 'Flamingo Swap Pair: FLM, GAS',
    hash: '0x7deb6406aeef3414ae47ae34fd986d0ca2c92859',
    tokens: [
      FLAMINGO_SWAP_TOKENS.testnet.FLM,
      FLAMINGO_SWAP_TOKENS.testnet.GAS,
    ],
  },
  'FLP-FLM-BNB': {
    name: 'Flamingo Swap Pair: FLM, fBNB',
    hash: '0xa89606941d015c1f5a14939198a0bf56399b560e',
    tokens: [
      FLAMINGO_SWAP_TOKENS.testnet.fBNB,
      FLAMINGO_SWAP_TOKENS.testnet.FLM,
    ],
  },
  'FLP-FLM-GM': {
    name: 'Flamingo Swap Pair: FLM, GM',
    hash: '0x635d478af99bef6cc53920836ec539e569907c8b',
    tokens: [FLAMINGO_SWAP_TOKENS.testnet.FLM, FLAMINGO_SWAP_TOKENS.testnet.GM],
  },
  'FLP-FLM-SWTH': {
    name: 'Flamingo Swap Pair: FLM, SWTH',
    hash: '0xc7f02273b619ef4b20f81e9ec41becc86f645b3d',
    tokens: [
      FLAMINGO_SWAP_TOKENS.testnet.FLM,
      FLAMINGO_SWAP_TOKENS.testnet.SWTH,
    ],
  },
  'FLP-bNEO-GAS': {
    name: 'Flamingo Swap Pair: bNEO, GAS',
    hash: '0x5a262ceef47ffcb82bfd3d94b916a2573e503e5a',
    tokens: [
      FLAMINGO_SWAP_TOKENS.testnet.bNEO,
      FLAMINGO_SWAP_TOKENS.testnet.GAS,
    ],
  },
  'FLP-bNEO-BNB': {
    name: 'Flamingo Swap Pair: bNEO, fBNB',
    hash: '0x1bcd02c98802365aa6722bc552fbdcae4397c17e',
    tokens: [
      FLAMINGO_SWAP_TOKENS.testnet.fBNB,
      FLAMINGO_SWAP_TOKENS.testnet.bNEO,
    ],
  },
  'FLP-bNEO-GM': {
    name: 'Flamingo Swap Pair: bNEO, GM',
    hash: '0xa83cc212789b7ec6c0ccffe109bc71413eefe3e1',
    tokens: [
      FLAMINGO_SWAP_TOKENS.testnet.bNEO,
      FLAMINGO_SWAP_TOKENS.testnet.GM,
    ],
  },
  'FLP-bNEO-fCAKE': {
    name: 'Flamingo Swap Pair: bNEO, fCAKE',
    hash: '0x3c7ae4f37c9ebf54536f9ce15cfd4359f6ea6ba1',
    tokens: [
      FLAMINGO_SWAP_TOKENS.testnet.fCAKE,
      FLAMINGO_SWAP_TOKENS.testnet.bNEO,
    ],
  },
  'FLP-bNEO-SWTH': {
    name: 'Flamingo Swap Pair: bNEO, SWTH',
    hash: '0x13e2a229e27f2aa567ad9fd5a1958a32691d18fc',
    tokens: [
      FLAMINGO_SWAP_TOKENS.testnet.SWTH,
      FLAMINGO_SWAP_TOKENS.testnet.bNEO,
    ],
  },
  'FRP-FLM-FDE': {
    name: 'Flamingo Swap Pair: FLM, FDE',
    hash: '0x4d30c0ab3d908126133813fb40cb153caf78d17d',
    tokens: [
      FLAMINGO_SWAP_TOKENS.testnet.FLM,
      FLAMINGO_SWAP_TOKENS.testnet.FDE,
    ],
  },
  'FRP-bNEO-FDE': {
    name: 'Flamingo Swap Pair: bNEO, FDE',
    hash: '0xaa4a2c7e9f5ddd2e6bfafc36d4b74e9ee171c6ae',
    tokens: [
      FLAMINGO_SWAP_TOKENS.testnet.FDE,
      FLAMINGO_SWAP_TOKENS.testnet.bNEO,
    ],
  },
  'FLP-FLM-FUSD': {
    name: 'Flamingo Swap Pair: FLM, FUSD',
    hash: '0x5d64cf72fa1d72aa6983218c2b286b136e05657e',
    tokens: [
      FLAMINGO_SWAP_TOKENS.testnet.FLM,
      FLAMINGO_SWAP_TOKENS.testnet.FUSD,
    ],
  },
  'FLP-bNEO-FUSD': {
    name: 'Flamingo Swap Pair: bNEO, FUSD',
    hash: '0x9ed3e179fb8f68d255bae6708c42fc66e32df566',
    tokens: [
      FLAMINGO_SWAP_TOKENS.testnet.bNEO,
      FLAMINGO_SWAP_TOKENS.testnet.FUSD,
    ],
  },
}

export const MAINNET_FLAMINGO_SWAP_POOLS: FlamingoSwapPools = {
  'FLP-FLM-bNEO': {
    name: 'Flamingo Swap Pair: FLM, bNEO',
    hash: '0x4d5a85b0c83777df72cfb665a933970e4e20c0ec',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.bNEO,
      FLAMINGO_SWAP_TOKENS.mainnet.FLM,
    ],
  },
  'FLP-FLM-GAS': {
    name: 'Flamingo Swap Pair: FLM, GAS',
    hash: '0x171d791c0301c332cfe95c6371ee32965e34b606',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.GAS,
      FLAMINGO_SWAP_TOKENS.mainnet.FLM,
    ],
  },
  'FLP-FLM-GM': {
    name: 'Flamingo Swap Pair: FLM, GM',
    hash: '0xf23221a92c29beffbea6e46c681c8380d9794579',
    tokens: [FLAMINGO_SWAP_TOKENS.mainnet.GM, FLAMINGO_SWAP_TOKENS.mainnet.FLM],
  },
  'FLP-FLM-SWTH': {
    name: 'Flamingo Swap Pair: FLM, SWTH',
    hash: '0xd8788aab4f7d84384f1808f9aaacd5dc4ea94317',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.SWTH,
      FLAMINGO_SWAP_TOKENS.mainnet.FLM,
    ],
  },
  'FLP-FLM-WBTC': {
    name: 'Flamingo Swap Pair: FLM, WBTC',
    hash: '0x73eab0fdd097c3b37aabeee37946458cfe2e523e',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.FLM,
      FLAMINGO_SWAP_TOKENS.mainnet.WBTC,
    ],
  },
  'FRP-FLM-FDE': {
    name: 'Flamingo Swap Pair: FLM, FDE',
    hash: '0x9f193ba476c934dd8847df26684063b2987b7508',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.FDE,
      FLAMINGO_SWAP_TOKENS.mainnet.FLM,
    ],
  },
  'FRP-FLM-NUDES': {
    name: 'Flamingo Swap Pair: FLM, NUDES',
    hash: '0x35de27f4d7bc356c9a4b734cabb38cc63657233f',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.NUDES,
      FLAMINGO_SWAP_TOKENS.mainnet.FLM,
    ],
  },
  'FRP-FLM-CANDY': {
    name: 'Flamingo Swap Pair: FLM, CANDY',
    hash: '0xf9956798ca7e8274f7ab4f1f6d6c06f55a0a9bd3',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.CANDY,
      FLAMINGO_SWAP_TOKENS.mainnet.FLM,
    ],
  },
  'FRP-FLM-DOGER': {
    name: 'Flamingo Swap Pair: FLM, DOGER',
    hash: '0xb3fb4160534d8c366c06d31fc8df7bc2b3428785',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.DOGER,
      FLAMINGO_SWAP_TOKENS.mainnet.FLM,
    ],
  },
  'FRP-FLM-SOM': {
    name: 'Flamingo Swap Pair: FLM, SOM',
    hash: '0x360dc86df056598a492f26baec4db38fcef65477',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.SOM,
      FLAMINGO_SWAP_TOKENS.mainnet.FLM,
    ],
  },
  'FRP-FLM-LRB': {
    name: 'Flamingo Swap Pair: FLM, LRB',
    hash: '0x1f86bab6d548a3bd4e9292be0937c074da78ab77',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.LRB,
      FLAMINGO_SWAP_TOKENS.mainnet.FLM,
    ],
  },
  'FLP-FLM-FUSD': {
    name: 'Flamingo Swap Pair: FLM, FUSD',
    hash: '0xaeae872ace15f87c117213c92c00944af789aed2',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.FUSD,
      FLAMINGO_SWAP_TOKENS.mainnet.FLM,
    ],
  },
  'FLP-FUSD-GAS': {
    name: 'Flamingo Swap Pair: FUSD, GAS',
    hash: '0x2f6ef6baaf7a9129cf0edf364798e75d5ee5eaf0',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.GAS,
      FLAMINGO_SWAP_TOKENS.mainnet.FUSD,
    ],
  },
  'FLP-FUSD-WBTC': {
    name: 'Flamingo Swap Pair: FUSD, WBTC',
    hash: '0xf7ba0fc88dd66dc39bfbaa7edefc255cd262b8df',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.FUSD,
      FLAMINGO_SWAP_TOKENS.mainnet.WBTC,
    ],
  },
  'FLP-FUSD-USDT': {
    name: 'Flamingo Swap Pair: FUSD, USDT',
    hash: '0x9ef4d5c1a686e13d72184b66e4bda77d7b4bae9f',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.FUSD,
      FLAMINGO_SWAP_TOKENS.mainnet.USDT,
    ],
  },
  'FLP-FUSD-CAKE': {
    name: 'Flamingo Swap Pair: FUSD, CAKE',
    hash: '0x495bb94a16c321f82cf70fc0d1182340aa9387fe',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.FUSD,
      FLAMINGO_SWAP_TOKENS.mainnet.CAKE,
    ],
  },
  'FLP-FUSD-ONT': {
    name: 'Flamingo Swap Pair: FUSD, ONT',
    hash: '0xde1908bbcfa2aabf2727d241fa306bb668ebb35e',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.FUSD,
      FLAMINGO_SWAP_TOKENS.mainnet.ONT,
    ],
  },
  'FLP-FUSD-WINGv2': {
    name: 'Flamingo Swap Pair: FUSD, WINGv2',
    hash: '0xd849d138824190bcb3ec54b38de656760d2cd578',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.FUSD,
      FLAMINGO_SWAP_TOKENS.mainnet.WINGv2,
    ],
  },
  'FLP-FUSD-BNB': {
    name: 'Flamingo Swap Pair: FUSD, BNB',
    hash: '0xcfb958afaa82dc0923486524246bdad08ab9c6ae',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.FUSD,
      FLAMINGO_SWAP_TOKENS.mainnet.BNB,
    ],
  },
  'FLP-FUSD-WETH': {
    name: 'Flamingo Swap Pair: FUSD, WETH',
    hash: '0xb9f87cb4f741bc5dc299132fe1b8372f01d37ca4',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.FUSD,
      FLAMINGO_SWAP_TOKENS.mainnet.WETH,
    ],
  },
  'FLP-bNEO-GAS': {
    name: 'Flamingo Swap Pair: bNEO, GAS',
    hash: '0x3244fcadcccff190c329f7b3083e4da2af60fbce',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.bNEO,
      FLAMINGO_SWAP_TOKENS.mainnet.GAS,
    ],
  },
  'FLP-bNEO-GM': {
    name: 'Flamingo Swap Pair: bNEO, GM',
    hash: '0xc658095f498dd3e00292c29ac1e85fe9ff206f28',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.bNEO,
      FLAMINGO_SWAP_TOKENS.mainnet.GM,
    ],
  },
  'FLP-bNEO-SWTH': {
    name: 'Flamingo Swap Pair: bNEO, SWTH',
    hash: '0x48d8b2b02b960aa8845a3f90d2c590d4e61a425c',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.bNEO,
      FLAMINGO_SWAP_TOKENS.mainnet.SWTH,
    ],
  },
  'FRP-bNEO-FDE': {
    name: 'Flamingo Swap Pair: bNEO, FDE',
    hash: '0x77994db5591ff4869d72fa31ffe3ace8d6435e6d',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.bNEO,
      FLAMINGO_SWAP_TOKENS.mainnet.FDE,
    ],
  },
  'FRP-bNEO-NUDES': {
    name: 'Flamingo Swap Pair: bNEO, NUDES',
    hash: '0x85a2053d65dcbda9208e2a7cfa65a9db09a1cf11',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.NUDES,
      FLAMINGO_SWAP_TOKENS.mainnet.bNEO,
    ],
  },
  'FRP-bNEO-CANDY': {
    name: 'Flamingo Swap Pair: bNEO, CANDY',
    hash: '0x29ea009d2bfecd5f65994ed54277348fb89d3e44',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.bNEO,
      FLAMINGO_SWAP_TOKENS.mainnet.CANDY,
    ],
  },
  'FRP-bNEO-DOGER': {
    name: 'Flamingo Swap Pair: bNEO, DOGER',
    hash: '0xdfaac72e7051e6cc218a9b77f228f0758f39a990',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.DOGER,
      FLAMINGO_SWAP_TOKENS.mainnet.bNEO,
    ],
  },
  'FRP-bNEO-SOM': {
    name: 'Flamingo Swap Pair: bNEO, SOM',
    hash: '0x5faf5e07e2dc09d1315cf6a49699be3feb0377d0',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.SOM,
      FLAMINGO_SWAP_TOKENS.mainnet.bNEO,
    ],
  },
  'FLP-bNEO-FUSD': {
    name: 'Flamingo Swap Pair: bNEO, FUSD',
    hash: '0x3269ece5dc33adf17ed14be7780693f3c8b102d1',
    tokens: [
      FLAMINGO_SWAP_TOKENS.mainnet.FUSD,
      FLAMINGO_SWAP_TOKENS.mainnet.bNEO,
    ],
  },
}

export const FLAMINGO_SWAP_POOLS: Record<BSNeo3NetworkId, FlamingoSwapPools> = {
  testnet: TESTNET_FLAMINGO_SWAP_POOLS,
  mainnet: MAINNET_FLAMINGO_SWAP_POOLS,
}
