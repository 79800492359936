import axios from 'axios'
import {EnvHelper} from '@/helpers/EnvHelper'

export type NetworkStatistics = {
  totalTransactions: number
  totalAddresses: number
  totalContracts: number
  currentWeekTransactions: number
  currentWeekAddresses: number
  currentWeekContracts: number
}

export class ShrikeHelper {
  static async getNetworkStatistics(): Promise<NetworkStatistics> {
    const url = `${EnvHelper.SHRIKE_URL}v1/stat/network-statistics`

    const {data} = await axios.get(url)

    return {
      totalTransactions: data.total_transactions,
      totalAddresses: data.total_addresses,
      totalContracts: data.total_contracts,
      currentWeekTransactions: data.current_week_transactions,
      currentWeekAddresses: data.current_week_addresses,
      currentWeekContracts: data.current_week_contracts,
    }
  }
}
